import LogIcon from '@mui/icons-material/Collections';
import LogList from './LogList';
import LogShow from './LogShow';
// import LogCreate from './LogCreate';

export default {
    list: LogList,
    // create: LogCreate,
    show: LogShow,
    icon: LogIcon,
};

import {
    DataProvider,
    UpdateParams,
    fetchUtils,
    withLifecycleCallbacks,
} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const apiUrl = process.env.REACT_APP_API_URL as string;

const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const { jwt } = JSON.parse(localStorage.getItem('auth') as string);
    options.headers.set('Authorization', `Bearer ${jwt}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider: DataProvider = withLifecycleCallbacks(
    simpleRestProvider(apiUrl, httpClient),
    [
        {
            resource: 'posts',
            beforeUpdate: async (
                params: UpdateParams<any>,
                dataProvider: DataProvider
            ): Promise<any> => {
                // Freshly dropped pictures are File objects and must be converted to base64 strings
                const newPictures = params.data.pictures.filter(
                    (p: any) => p.rawFile instanceof File
                );
                const formerPictures = params.data.pictures.filter(
                    (p: any) => !(p.rawFile instanceof File)
                );

                const base64Pictures = await Promise.all(
                    newPictures.map(convertFileToBase64)
                );
                const pictures = [
                    ...base64Pictures.map((dataUrl, index) => ({
                        src: dataUrl,
                        title: newPictures[index].name,
                    })),
                    ...formerPictures,
                ];
                return dataProvider.update('posts', {
                    ...params,
                    data: { ...params.data, pictures },
                });
            },
        },
        {
            resource: 'transaction/log',
            afterGetOne: async params => {
                console.log(params);
                // params.data.id = params.data.response.id;
                return params;
            },
        },
    ]
);

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });

export default dataProvider;

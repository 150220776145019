import * as React from 'react';
import {
    Datagrid,
    DateField,
    EditButton,
    Pagination,
    ReferenceManyField,
    TabbedForm,
    TextField,
    useRecordContext,
    Show,
    SimpleShowLayout,
} from 'react-admin';

import CustomerReferenceField from '@/visitors/CustomerReferenceField';
import StarRatingField from '../reviews/StarRatingField';
import CreateRelatedReviewButton from './CreateRelatedReviewButton';
import { JsonField } from 'react-admin-json-view';
import { TransactionLog } from '../types/transaction/types';

const LogTitle = () => {
    const record = useRecordContext<TransactionLog>();
    if (record) {
        record.merchantId = record.request.merchantId;
    }
    return record ? <span>Poster "{record.reference}"</span> : null;
};

const LogShow = () => {
    return (
        <Show title={<LogTitle />}>
            <SimpleShowLayout>
                <TabbedForm>
                    <TabbedForm.Tab
                        label="Request"
                        path=""
                        sx={{ maxWidth: '40em' }}
                    >
                        <JsonField
                            source="request"
                            jsonString={false}
                            reactJsonOptions={{
                                name: null,
                                collapsed: false,
                                enableClipboard: false,
                                displayDataTypes: false,
                            }}
                        />{' '}
                    </TabbedForm.Tab>
                    <TabbedForm.Tab
                        label="Response"
                        path="response"
                        sx={{ maxWidth: '40em' }}
                    >
                        <JsonField
                            source="response"
                            jsonString={false}
                            reactJsonOptions={{
                                name: null,
                                collapsed: false,
                                enableClipboard: false,
                                displayDataTypes: false,
                            }}
                        />{' '}
                    </TabbedForm.Tab>
                    <TabbedForm.Tab
                        label="Payments"
                        // count={
                        //     <ReferenceManyCount
                        //         reference="transaction/count"
                        //         target="transactionId"
                        //         sx={{ lineHeight: 'inherit' }}
                        //     />
                        // }
                        path="reviews"
                    >
                        <ReferenceManyField
                            reference="transaction/payment"
                            target="merchantId"
                            pagination={<Pagination />}
                        >
                            <Datagrid
                                sx={{
                                    width: '100%',
                                    '& .column-comment': {
                                        maxWidth: '20em',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    },
                                }}
                            >
                                <DateField source="date" />
                                <CustomerReferenceField />
                                <StarRatingField />
                                <TextField source="comment" />
                                <TextField source="status" />
                                <EditButton />
                            </Datagrid>
                            <CreateRelatedReviewButton />
                        </ReferenceManyField>
                    </TabbedForm.Tab>
                </TabbedForm>
            </SimpleShowLayout>
        </Show>
    );
};

export default LogShow;

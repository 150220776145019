import { AuthProvider } from 'react-admin';

const apiUrl = process.env.REACT_APP_API_URL as string;

const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        const request = new Request(apiUrl + '/auth/login', {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch(() => {
                throw new Error('Network error');
            });
    },
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        localStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),
    // getPermissions: () => Promise.reject('Unknown method'),
    getPermissions: () => Promise.resolve(),
    getIdentity: () => {
        const json = JSON.parse(localStorage.getItem('auth') as string);
        // console.log(json);
        return Promise.resolve(
            json
            // {
            //     id: 1,
            //     fullName: 'Jane Doe',
            //     avatar:
            //         'data:image/jpeg;base64,/9j/4=',
            // },
        );
    },
};

export default authProvider;

import MerchantIcon from '@mui/icons-material/Bookmark';

import MerchantList from './MerchantList';
import MerchantEdit from './MerchantEdit';

export default {
    list: MerchantList,
    edit: MerchantEdit,
    icon: MerchantIcon,
};

import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Transaction } from '../types/transaction/types';

export const UserCardField = () => {
    const record = useRecordContext<Transaction>();

    return record ? (
        <span>
            <b>{record.userCard?.typeId}</b> **&nbsp;{record.userCard?.last4}
        </span>
    ) : null;
};

UserCardField.defaultProps = {
    label: 'resources.customers.fields.name',
};

export default UserCardField;

import OrderIcon from '@mui/icons-material/AttachMoney';

import TransactionList from './TransactionList';
import TransactionShow from './TransactionShow';
import TransactionEdit from './TransactionEdit';

export default {
    list: TransactionList,
    show: TransactionShow,
    edit: TransactionEdit,
    icon: OrderIcon,
};

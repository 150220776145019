import * as React from 'react';
import inflection from 'inflection';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    SavedQueriesList,
    useGetList,
} from 'react-admin';

import { Category } from '../types';

const Aside = () => {
    const { data } = useGetList<Category>('merchant/merchant', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
    });

    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch hiddenLabel />

                <SavedQueriesList />

                <FilterList
                    label="resources.log.filters.sales"
                    icon={<AttachMoneyIcon />}
                >
                    <FilterListItem
                        label="POST"
                        value={{
                            method: 'POST',
                        }}
                    />
                    <FilterListItem
                        label="PATCH"
                        value={{
                            method: 'PATCH',
                        }}
                    />
                    <FilterListItem
                        label="PUT"
                        value={{
                            method: 'PUT',
                        }}
                    />
                    <FilterListItem
                        label="DELETE"
                        value={{
                            method: 'DELETE',
                        }}
                    />
                </FilterList>

                <FilterList
                    label="resources.log.filters.stock"
                    icon={<BarChartIcon />}
                >
                    <FilterListItem
                        label="resources.log.filters.no_stock"
                        value={{
                            stock_lt: undefined,
                            stock_gt: undefined,
                            stock: 0,
                        }}
                    />
                    <FilterListItem
                        label="resources.log.filters.low_stock"
                        value={{
                            stock_lt: 10,
                            stock_gt: 0,
                            stock: undefined,
                        }}
                    />
                    <FilterListItem
                        label="resources.log.filters.average_stock"
                        value={{
                            stock_lt: 50,
                            stock_gt: 9,
                            stock: undefined,
                        }}
                    />
                    <FilterListItem
                        label="resources.log.filters.enough_stock"
                        value={{
                            stock_lt: undefined,
                            stock_gt: 49,
                            stock: undefined,
                        }}
                    />
                </FilterList>

                <FilterList
                    label="resources.log.filters.merchant"
                    icon={<LocalOfferIcon />}
                >
                    {data &&
                        data.map((record: any) => (
                            <FilterListItem
                                label={inflection.humanize(record.name)}
                                key={record.id}
                                value={{ category_id: record.id }}
                            />
                        ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;
